import React, { useState } from 'react';
import phoneMarketplace from '../../../assets/images/phone-marketplace.png';
import homePink from '../../../assets/images/home-pink.svg';
import Button from '../../common/Button/Button';
import './Marketplace.scss';
import ModalForm from '../../ui/ModalForm/ModalForm';

const Marketplace = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className='Marketplace' id='programa'>
      <div className='container-marketplace'>
        <div className='infos-marketplace'>
          <p><strong>Aprenda sobre IA com a IBM <br /> e fique em sintonia com o mercado de trabalho!</strong></p>
          <p>O setor de tecnologia, devido à velocidade da sua evolução, tem mais vagas de emprego do que profissionais disponíveis para preenchê-las, uma tendência que se mantém há uma década e que tem previsão de perdurar. </p>
          <p>Mas apesar das projeções apontarem para a criação de mais de 400 mil postos de trabalho até 2025, é importante que você, que está ingressando no mercado ou planejando uma transição de carreira, direcione seus esforços para conquistar o seu lugar.</p>
          <p>O Match! entra em cena para oferecer conteúdos que vão abrir portas para você dar os primeiros passos no universo da tecnologia, um setor que só cresce e hoje tem mais vagas de emprego do que profissionais disponíveis para preenchê-las.</p>
          <p>Composto por dois módulos,  “IA e Tecnologias Emergentes” e “Fundamentos da Tecnologia para Atendimento ao Cliente Digital”, a nossa jornada formati-va aborda os conceitos fundamentais dessas frentes de atuação que têm se revelado indispensáveis, garante certificações reconhecidas por grandes empresas e ainda contribui para a construção do seu portfólio!</p>
          <p>Inscreva-se e se prepare para as possibilidades que se apresentarão para você!</p>
          <Button onClick={() => openModal()}>COMECE AGORA. INSCREVA-SE.</Button>
        </div>
        <img src={phoneMarketplace} alt='Telefone' />
      </div>

      <div className='container-info'>
        <div>
          <img src={homePink} alt='casa cor de rosa' />
          <p>Qualificação gratuita e on-line em tecnologia!</p>
        </div>
      </div>

      {isModalOpen && <ModalForm closeModal={closeModal} />}
    </div>
  );
}

export default Marketplace;