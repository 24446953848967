export const partnerList = [
  {
    "url": "https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=match-capacitame",
    "partnerCode": "match-capacitame"
  },
  {
    "url": "https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=match-horaluterana",
    "partnerCode": "match-hora-luterana"
  },
  {
    "url": "https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=match-impacthub",
    "partnerCode": "impacthub"
  },
  {
    "url": "https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=match-impacthub",
    "partnerCode": "match-impacthub"
  },
  {
    "url": "https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=match-vivario",
    "partnerCode": "vivario"
  },
  {
    "url": "https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=match-vivario",
    "partnerCode": "match-vivario"
  },
  {
    "url": "https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=open-Mastertech",
    "partnerCode": "match-ia"
  },
]

export const getPartnerData = (partnerCode) => {
  return partnerList.find(p => p.partnerCode === partnerCode);
};
