import { processResponse } from "./common";

const headers = {
  "Content-Type": "application/json",
  Authorization: "Token cdda1111d17538e7fb898bd9b893cb12381e79b3"
};

export const apiLeadRegister = payload =>
  fetch('https://leadcollector.mastertech.com.br/api/leads', {
    method: "POST",
    headers: headers,
    body: JSON.stringify(payload)
  }).then(processResponse);
