import { createContext, useContext, useState } from 'react';

const UserContext = createContext();

export function UserProvider({ children, partner }) {
  const [email, setEmail] = useState('');
  const [partnerCode, setPartnerCode] = useState(partner);

  const updateUserProjectInfo = async (newProjectInfo) => {
    setEmail(prevUserData => {
      const newUserData = { ...prevUserData };
      newUserData.project_info = newUserData.project_info || {};
      newUserData.project_info = { ...newUserData.project_info, ...newProjectInfo };
      return newUserData;
    });
  };

  const updatePartnerCode = (code) => {
    setPartnerCode(code);
  };

  return (
    <UserContext.Provider value={{ email, setEmail, updateUserProjectInfo, updatePartnerCode, partnerCode }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}