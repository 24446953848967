import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import logoMatch from '../../../assets/images/match-logo.png';
import NavigationLink from '../../common/NavigationLink/NavigationLink';
import { getPartnerData } from '../../hooks/partnerList';
import { useUser } from '../../hooks/UserContext';
import ModalForm from '../ModalForm/ModalForm';
import './Navbar.scss';

const Navbar = () => {
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [classNav, setClassNav] = useState("");
  const { hash } = useLocation();
  const { partnerCode, updatePartnerCode } = useUser();
  const location = useLocation();
  const { partner } = useParams();

  const buildNavigationLink = (hash) => {
    if (partner) {
      return `/parceiro/${partner}${hash}`;
    }
    return hash;
  };

  useEffect(() => {
    if (partner) {
      updatePartnerCode(partner);
    }
  }, [partner]);

  const handleMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
      setClassNav("menu-closed");
    } else {
      setMenuOpen(true);
      setClassNav("menu-active");
    }
  };

  useEffect(() => {
    setClassNav('menu-closed')
  }, [hash, pathname])

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <nav className={`Navbar ${classNav}`}>
        <a href={window.location.origin}>
          <img alt='Logo da Impulso' src={logoMatch} />
        </a>
        <button className='menu-mobile'>
          <div aria-label={menuOpen ? "fechar menu" : "abrir menu"} onClick={handleMenu}>
            <span />
            <span />
            <span />
          </div>
        </button>
        <div>
          <NavigationLink href={buildNavigationLink('#jornada')} active={location.hash === '#jornada'}>A jornada</NavigationLink>
          <NavigationLink href={buildNavigationLink('#metodologia')} active={location.hash === '#metodologia'}>A metodologia</NavigationLink>
          <NavigationLink href={buildNavigationLink('#estatisticas')} active={location.hash === '#estatisticas'}>As estatísticas</NavigationLink>
          <NavigationLink href={buildNavigationLink('#idealizadores')} active={location.hash === '#idealizadores'}>Os idealizadores</NavigationLink>
          <button onClick={openModal} className="btn-info">INCREVA-SE GRATUITAMENTE</button>
        </div>
      </nav>
      {isModalOpen && <ModalForm closeModal={closeModal} />}

    </>
  )
}

export default Navbar;
