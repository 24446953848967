import React, { useState } from 'react';
import { apiLeadRegister } from '../../../api/lead';
import { getPartnerData } from '../../hooks/partnerList';
import { useUser } from '../../hooks/UserContext';
import './ModalForm.scss';

const ModalForm = ({ closeModal }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({});
  const { partnerCode } = useUser();
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let tempErrors = {};
    if (!name) tempErrors.name = 'Nome é obrigatório';
    if (!email) {
      tempErrors.email = 'E-mail é obrigatório';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = 'E-mail inválido';
    }
    if (!phone) {
      tempErrors.phone = 'Telefone é obrigatório';
    } else if (!/^\d{10,11}$/.test(phone)) {
      tempErrors.phone = 'Número de telefone inválido';
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const verifyLink = () => {
    const url = getPartnerData(partnerCode)?.url ||
      'https://skills.yourlearning.ibm.com/activity/PLAN-77C3CABFEC6A?utm_campaign=open-Mastertech';

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (!newWindow) {
      alert("Abrindo na mesma aba devido a bloqueio de pop-ups.");
      window.location.assign(url);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const payload = {
        name,
        company: partnerCode ? partnerCode : "Match",
        email,
        telephone: phone,
        origin: "Match",
        fields: {
          message: "Contato Match!",
        }
      };

      setLoading(true);
      apiLeadRegister(payload)
        .then(() => {
          setLoading(false);
          verifyLink();
          resetForm();
          closeModal();
        })
        .catch(() => {
          setLoading(false);
          verifyLink();
          resetForm();
          closeModal();
        });
    }
  };

  const resetForm = () => {
    setName("");
    setPhone("");
    setEmail("");
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('ModalForm')) {
      closeModal();
    }
  };

  return (
    <div className="ModalForm" onClick={handleOutsideClick}>
      <div className="modal-content">
        <button className="close-btn" onClick={closeModal}>×</button>
        <h2>Falta pouco!</h2>
        <p>Preencha seus dados: nome, e-mail e telefone abaixo:</p>
        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <input
              type="text"
              placeholder="Insira seu nome"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <p className="error">{errors.name}</p>}
          </div>
          <div className="input-field">
            <input
              type="email"
              placeholder="Insira o seu melhor e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <div className="input-field">
            <input
              type="tel"
              placeholder="Insira seu telefone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            {errors.phone && <p className="error">{errors.phone}</p>}
          </div>
          {loading && <p className="loading">Enviando...</p>}
          {!loading && <button type="submit" className="submit-btn">COMEÇAR AGORA</button>}
        </form>
        <p className="footer-text">
          O Programa Match! é destinado exclusivamente a pessoas maiores de 18 anos.
          Ao prosseguir com a inscrição e participação no curso, você declara que possui 18 anos ou mais.
        </p>
      </div>
    </div>
  );
};

export default ModalForm;
